@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-snow{
    background-color: #FFFAFA;
}

.text-snow{
    color : #FFFAFA;
}

.text-blue-site{
    color : #00AFB5;
}

.bg-blue-site{
    background-color: #00AFB5;
}

.bg-orange-site{
    background-color: #F18805;
}

.text-orange-site{
  color: #F18805;
}